import { useState, useEffect } from 'react';
import { LesteSakerContext } from '../contexts/LesteSakerContext';

import Header from '../components/Header/Header';
import NewsArticle from '../components/NewsArticle/NewsArticle';
import NewsContainer from '../components/NewsContainer/NewsContainer';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import HorizontalRuler from '../components/HorizontalRuler/HorizontalRuler';

function getLesteSakIder() {
  const ider = new Set();
  for (let i = 0; i < localStorage.length; ++i) {
    ider.add(localStorage.key(i));
  }
  return ider;
}

export default function Home() {
  const [saker, setSaker] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lesteSakIder, setLesteSakIder] = useState(
    getLesteSakIder());
  useEffect(() => {
    async function hentSaker() {
      const res = await fetch("/saker.json");
      return await res.json();
    }
    hentSaker()
      .then(data => setSaker(data))
      .then(() => {
        setIsLoading(false);
      })
  }, []);
  function markerSomLest(sakId) {
    try {
      localStorage.setItem(sakId, "true");
    } catch (e) {
      /**
       * @TODO
       * Bedre håndtering av at localStorage kan bli fullt.
       */
      console.error(`Feilet å legge til saks-ID i local storage: ${sakId}. Er localStorage fullt?`);
    }
    setLesteSakIder(prev => {
      prev.add(sakId);
      return new Set(prev);
    });
  }
  function markerSomUlest(sakId) {
    localStorage.removeItem(sakId);
    setLesteSakIder((prev) => {
      prev.delete(sakId);
      return new Set(prev);
    });
  }
  const ulesteSaker = saker.filter(sak => !lesteSakIder.has(sak.id));
  return (
    <main>
      <p>
        Tjenesten NRKort er avpublisert og avviklet gjeldende fra og med den 10. januar 2024.
      </p>
    </main>
  );
  // return (
  //   <>
  //     <Header />
  //     <main>
  //       <LesteSakerContext.Provider value={{
  //         lesteSakIder,
  //         markerSomLest,
  //         markerSomUlest
  //       }}>
  //         {
  //           isLoading
  //             ? <NewsContainer>
  //                 <LoadingSpinner />
  //               </NewsContainer>
  //             : <NewsContainer>
  //                 {
  //                   ulesteSaker.length > 0
  //                     ? ulesteSaker.map(sak => {
  //                         return <NewsArticle key={ sak.id } sak={ sak } />
  //                       })
  //                     : <p>Du har ingen uleste saker. ✅</p>
  //                 }
  //                 {
  //                   lesteSakIder.size > 0 && <HorizontalRuler />
  //                 }
  //                 {
  //                   saker
  //                     .filter(sak => lesteSakIder.has(sak.id))
  //                     .map(sak => {
  //                       return <NewsArticle key={ sak.id } sak={ sak } />;
  //                     })
  //                 }
  //               </NewsContainer>
  //         }
  //       </LesteSakerContext.Provider>
  //     </main>
  //   </>
  // );
}
